import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import SimpleNavar from './../components/SimpleNavbarComponent/SimpleNavbar';
import Footer from './../components/FooterComponent/Footer';

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>404: Not found</title>
        </Helmet>
        <SimpleNavar />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <div className="footer">
          <Footer />
        </div>
      </div>
    )
  }
}

export default NotFoundPage
